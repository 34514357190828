<template>
    <div>
        <span class="flex items-center text-blue-500 text-sm cursor-pointer select-none" @click="isOpen = !isOpen">
            <icon v-if="!isOpen" name="plus" class="w-4 h-4 inline-block mr-1"></icon>
            <icon v-else name="minus" class="w-4 h-4 inline-block mr-1"></icon>
            <span class="mb-1">Add a co-resident</span>
        </span>
        <div class="bg-gray-100 p-4 border mt-1 mb-3" v-if="isOpen">
            <div class="form-row">
                <div class="form-col">
                    <label for="av_first_name">first name</label>
                    <input type="text" class="form-input" id="av_first_name" v-model="personalInfo.first_name" />
                    <span v-if="hasError('personalInfo.first_name')" class="form-error">{{ showError('personalInfo.first_name') }}</span>
                </div>
                <div class="form-col">
                    <label for="av_last_name">last name</label>
                    <input type="text" class="form-input" id="av_last_name" v-model="personalInfo.last_name" />
                    <span v-if="hasError('personalInfo.last_name')" class="form-error">{{ showError('personalInfo.last_name') }}</span>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="av_phone">phone</label>
                    <input type="text" class="form-input" id="av_phone" maxlength="14"  v-model="personalInfo.phone" @input="formatPhoneInput" />
                    <span v-if="hasError('personalInfo.phone')" class="form-error">{{ showError('personalInfo.phone') }}</span>
                </div>
                <div class="form-col">
                    <label for="av_email">email</label>
                    <input type="email" class="form-input" id="av_email" v-model="personalInfo.email"/>
                    <span v-if="hasError('personalInfo.email')" class="form-error">{{ showError('personalInfo.email') }}</span>
                </div>
            </div>
              <CheckGroupBoxHorizontal
                     name="noOfBathroom"
                     v-model="contact"
                     label="contact preferences"
                     :options='[{"key":"email","value":"email","checked":true},{"key":"sms","value":"sms","checked":false},{"key":"phone","value":"phone","checked":false}]'
                     editMode
                />
            <div class="mt-4 text-right">
                <button class="btn-primary btn-transparent" @click="close">cancel</button>
                <button class="btn-primary ml-2" @click="save">save</button>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import {slugify} from "@/utils/String";
import CheckGroupBoxHorizontal from "@/components/guest_cards/common/CheckGroupBoxHorizontal";

export default {
    components: { Icon ,CheckGroupBoxHorizontal},
    emits: ['co-resident-saved'],
    data: () => {
        return {
            isOpen: false,
            personalInfo: {
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
            },
            contact: [],
            contactId : null
        }
    },
    methods: {
         formatPhoneInput(){ 
                var x = this.personalInfo.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.personalInfo.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        save() {
            slugify('')
            if(this.validator.passes()) {
                this.$emit('co-resident-saved', {
                    personalInfo: this.personalInfo,
                    contact: this.contact,
                });
                console.log()
                this.close();
            }
        },
        close() {
            this.personalInfo = {
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
            };
            this.contact =  {
                email: false,
                sms: false,
                phone: false
            };
            this.isOpen = false;
        }
    },
    validations: {
        'personalInfo.first_name': 'required',
        'personalInfo.last_name': 'required',
        'personalInfo.email': 'required | email',
        'personalInfo.phone': 'required',
    },
    created() {
        this.initValidator();
    }
}
</script>

<template>
    <div v-if="unitIsNotEmpty" class="mb-8 pb-4 border-b">
        <!-- associated visitors -->
        <div v-for="(coResident, index) in coResidents" :key="'visitor' + index">
            <div class="px-2 py-1 bg-blue-200 text-blue-800 font-thin mb-2 flex justify-between items-center">
                <div>
                   <span class="font-medium">
                       {{ coResident.personalInfo.first_name }} {{ coResident.personalInfo.last_name }}
                   </span>
                    - co. resident
                </div>
                <div>
                    <icon name="close" class="w-3 h-3 inline text-blue-300 cursor-pointer" @click="$emit('remove-associated-visitor', coResident)"></icon>
                </div>
            </div>
        </div>

        <!-- occupants -->
        <div v-for="(occupant, index) in occupants" :key="'occupant-' + index">
            <div class="px-2 py-1 bg-blue-200 text-blue-800 font-thin mb-2 flex justify-between items-center">
                <div>
                   <span class="font-medium">
                       {{ occupant.personalInfo.first_name }} {{ occupant.personalInfo.last_name }}
                   </span>
                    - occupant
                </div>
                <div>
                    <icon name="close" class="w-3 h-3 inline text-blue-300 cursor-pointer" @click="$emit('remove-occupant', occupant)"></icon>
                </div>
            </div>
        </div>

        <!-- pets -->
        <div v-for="(pet, index) in pets" :key="'pet-' + index">
            <div class="px-2 py-1 bg-blue-200 text-blue-800 font-thin mb-2 flex justify-between items-center">
                <div>
                   <span class="font-medium">{{ pet.petInfo.name   }}</span> - pet {{ (pet.petInfo.service_animal ? " (service animal)" : "")}}
                </div>
                <div>
                    <icon name="close" class="w-3 h-3 inline text-blue-300 cursor-pointer" @click="$emit('remove-pet', pet)"></icon>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
    components: { Icon },
    props: {
        coResidents: {
            required: true,
            type: Array,
            default: () => { return [] }
        },
        occupants: {
            required: true,
            type: Array,
            default: () => { return [] }
        },
        pets: {
            required: true,
            type: Array,
            default: () => { return [] }
        }
    },
    emits: [
        'remove-pet',
        'remove-occupant',
        'remove-co-resident',
        'remove-associated-visitor'
    ],
    computed: {
        unitIsNotEmpty() {
            return this.coResidents.length || this.occupants.length || this.pets.length;
        }
    }
}
</script>

<template>
    <div>
        <span class="flex items-center text-blue-500 text-sm cursor-pointer select-none" @click="isOpen = !isOpen">
            <icon v-if="!isOpen" name="plus" class="w-4 h-4 inline-block mr-1"></icon>
            <icon v-else name="minus" class="w-4 h-4 inline-block mr-1"></icon>
            <span class="mb-1">Add a pet</span>
        </span>
        <div class="w-full bg-gray-100 p-4 border mt-1 mb-3" v-if="isOpen">
            <div class="form-row">
                <div class="form-col">
                    <label for="pet_name">name</label>
                    <text-input id="pet_name" v-model="petInfo.name" />
                    <span v-if="hasError('petInfo.name')" class="form-error">{{ showError('petInfo.name') }}</span>
                </div>
                <div class="form-col">
                    <label>type</label>
                    <dropdown v-model="petInfo.type" :options="typeOptions"></dropdown>
                    <span v-if="hasError('petInfo.type')" class="form-error">{{ showError('petInfo.type') }}</span>
                </div>
            </div>
            <div class="w-full flex mt-4">
                <div class=" w-4/6 mr-3">
                    <div style="margin-bottom:10px; font-weight: 600;">breed</div>
                    <dropdown v-model="petInfo.breed" :options="breedOptions"></dropdown>
                    <span v-if="hasError('petInfo.breed')" class="form-error">{{ showError('petInfo.breed') }}</span>
                </div>
                 <div class=" w-2/6 ">
                    <div style="margin-bottom:10px; font-weight: 600;">service animal</div>
                    <input type="checkbox"  v-model="petInfo.service_animal">
                    <span v-if="hasError('petInfo.breed')" class="form-error">{{ showError('petInfo.breed') }}</span>
                </div>
            </div>

            <div class="mt-4 text-right">
                <button class="btn-primary btn-transparent" @click="close">cancel</button>
                <button class="btn-primary ml-2" @click="save">save</button>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Dropdown from "@/components/ui/Dropdown";
import TextInput from "@/components/ui/TextInput";
import { petTypeOptions, dogsOptions, catsOptions, birdsOptions, miscPetsOptions } from "@/utils/GuestCards";

export default {
    components: { Icon, Dropdown, TextInput },
    emits: ['pet-saved'],
    data: () => {
        return {
            isOpen: false,
            petInfo: {
                name: null,
                breed: null,
                type: null,
                service_animal:false
            },
            typeOptions: petTypeOptions,
            breedOptions: [],
            breeds: {
                Dogs: dogsOptions,
                Cats: catsOptions,
                Birds: birdsOptions,
                Miscellaneous: miscPetsOptions,
            }
        }
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.$emit('pet-saved', {
                 
                    petInfo: this.petInfo,
                });
                this.close();
            }
        },
        close() {
            this.petInfo =  {
                name: null,
                breed: null,
                type: null,
            };
            this.isOpen = false;
        }
    },
    watch: {
        'petInfo.type': {
            handler: function() {
                if(this.petInfo.type)
                    this.breedOptions = this.breeds[this.petInfo.type];
                else {
                    this.breedOptions = [];
                    this.petInfo.breed = null;
                }
            },
            immediate: true
        }
    },
    validations: {
        'petInfo.name': 'required',
        'petInfo.breed': 'required',
        'petInfo.type': 'required',
    },
    created() {
        this.initValidator();
    }
}
</script>

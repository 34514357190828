<template>
    <div class="antialiased">
        <!-- first row -->
        <div class="flex w-full mb-8">
            <div class="w-3/4">
               <CheckGroupBoxHorizontal
                name="noOfBedroom"
                v-model="apartment.numberOfBedrooms"
                label="no. of bedrooms?"
                :options='bedroomsOptions'
                editMode
                />          
            </div>
            <div class="w-1/2">
                <form-label>what is their maximum rent budget</form-label>
                <div class="flex justify-start items-center"> 
                    <div class="w-3/5">
                        <input type="range" :min="minAmount" :max="maxAmount" v-model="apartment.amount" />
                    </div>
                    <div class="flex justify-start items-center w-2/5 ml-4 border outline-none font-400 p-3 text-base">
                        <span class="mr-1">$</span>
                        <input type="number" :min="minAmount" @blur="validateBudget" class="inline focus:outline-none w-32" v-model="apartment.amount" />
                    </div>
                </div>
            </div>
        </div>
        <!-- second row -->
        <div class="flex w-full mb-8">
            <div class="flex w-3/5">
                <div class="w-3/4">
                    <CheckGroupBoxHorizontal
                     name="noOfBathroom"
                     v-model="apartment.preferredBathroom"
                     label="no. of bathroom"
                     :options="noOfBathroomsOptions"
                     editMode
                     />          
                </div>
            </div>
            <div class="">
                <form-label>preferred move-in date</form-label>
                <div class="flex items-center">
                    <div class="w-3/5">
                        <input type="date" id="from-date" :min="currentDate" class="form-input" v-model="apartment.move_in_start_date" />
                        <span v-if="hasError('apartment.move_in_start_date')" class="form-error">{{ showError('apartment.move_in_start_date') }}</span>
                    </div>
                    <div class="text-center mx-2 w-2/5">
                        to
                    </div>
                    <div class="w-3/5">
                        <input type="date" class="form-input" :min="apartment.move_in_start_date ? apartment.move_in_start_date : currentDate" v-model="apartment.move_in_end_date" />
                        <span v-if="hasError('apartment.move_in_end_date')" class="form-error">{{ showError('apartment.move_in_end_date') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- third row -->
        <div class="flex w-full mb-8">
            <div class="flex w-3/5">
                <div>
                    <form-label>preferred layout information</form-label>
                    <textarea id="w3review" v-model="apartment.preferredLayout" class="input-style" rows="4" cols="60" maxlength="150"></textarea>
                    <br/>
                    <span class="char_tracker">{{(150- (apartment.preferredLayout ? apartment.preferredLayout.length : 0 ) )}}/150</span>
                    <span v-if="hasError('apartment.preferredLayout')" class="form-error">{{ showError('apartment.preferredLayout') }}</span>
                </div>
            </div>
             <div>
                <form-label>how long of a lease are they looking for?</form-label>
                <div>
                    <dropdown :placeholder="'select length'" v-model="apartment.lease_length" :options="leaseOptions"></dropdown>
                    <span v-if="hasError('apartment.lease_length')" class="form-error">{{ showError('apartment.lease_length') }}</span>
                </div>
            </div>
        </div>
        <!-- fourth row -->
        <div class="flex w-full mb-8">
            <div class="flex w-3/5">
                <div>
                    <form-label>preferred amenities information</form-label>
                    <textarea id="w3review" v-model="apartment.preferredAmenities" class="input-style" rows="4" cols="60" maxlength="150"></textarea>
                    <br/>
                    <span class="char_tracker">{{(150- (apartment.preferredAmenities ? apartment.preferredAmenities.length : 0 ) )}}/150</span>
                    <span v-if="hasError('apartment.preferredAmenities')" class="form-error">{{ showError('apartment.preferredAmenities') }}</span>
                </div>
            </div>
            <div>
                <form-label>household size</form-label>
                <dropdown :placeholder="'select size'"  :options="householdSizeOptions" v-model="apartment.house_hold_size" ></dropdown>
                <span v-if="hasError('apartment.house_hold_size')" class="form-error">{{ showError('apartment.house_hold_size') }}</span>
            </div>
        </div>
        <modal-footer :footer="footer" :primary="next" :secondary="back" :tertiary="cancel" ></modal-footer>
    </div>
</template>

<script>    
import moment from "moment";
import {mapGetters} from "vuex";
import EventBus from "@/utils/EventBus";
import CheckGroupBoxHorizontal from "@/components/guest_cards/common/CheckGroupBoxHorizontal";
import Dropdown from "@/components/ui/Dropdown";
import FormLabel from "@/components/ui/FormLabel";
import { fillDropdownOptions, bedroomTypes } from "@/utils/GuestCards";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import AuthMixin from "@/components/auth/AuthMixin";

export default {
    components: { ModalFooter, Dropdown, FormLabel,CheckGroupBoxHorizontal },
    mixins: [ ModalNavigation, AuthMixin ],
    data: () => {
        return {
            currentDate:new Date().toISOString().split('T')[0],
            floorplans: [],
            apartment: {
                preferredLayout: null,
                preferredAmenities: null,
                preferredBathroom: null,
                house_hold_size: null,
                move_in_start_date: null,
                move_in_end_date: null,
                lease_length: null,
                amount: 750,
                numberOfBedrooms: [],
            },
            noOfBathroomsOptions:[],
            householdSizeOptions: [
                 {
                    key:1,
                    value:'1'
                },
                {
                    key:2,
                    value:'2'
                },
                  {
                    key:3,
                    value:'3'
                }
            ],
            bedroomsOptions: [],
            minAmount: 0,
            maxAmount: 6000,
            leaseOptions: [],
            footer: {
                primaryButton: 'next',
                secondaryButton: 'back',
                tertiaryButton: 'cancel'
            },
        }
    },
    computed: {
        ...mapGetters({
            floorplansList: 'floorplans/getFloorplans',
            getCommOptions: 'guest_cards/getCommOptions',
        })
    },
    methods: {
        validateBudget(e = null){
            if(e){
                if(parseInt(e.target.value) >= 6000){
                    e.target.value = 6000
                }
            }else{
                if(this.apartment.amount >= 6000){
                    this.apartment.amount = 6000
                }
            }
        },
        cancel() {
            this.close();
        },
        back() {
            EventBus.emit('previous-step');
        },
        next() {
            this.validateBudget();
            if(this.validator.passes()) {
                EventBus.emit('guest-card-apartment-info-filled', {
                    apartment: this.apartment
                });
                EventBus.emit('next-step');
            }
        },
        formatDates() {
            this.apartment.move_in_start_date = this.apartment.move_in_start_date ? moment(this.apartment.move_in_start_date).format('YYYY-MM-D') : null;
            this.apartment.move_in_end_date = this.apartment.move_in_end_date ? moment(this.apartment.move_in_end_date).format('YYYY-MM-D') : null;
        },
        loadHouseHoldSizeOptions() {
            this.householdSizeOptions = fillDropdownOptions(8);
        },
        loadLayoutOptions() {
            this.floorplansList.forEach( (floorplan) => {
                this.layoutOptions.push( { key: floorplan.common_name, value: floorplan.common_name } );
            })
        }
    },
    validations: {
         'apartment.preferredLayout': 'required',
         'apartment.preferredAmenities': 'required',
         'apartment.house_hold_size': 'required',
         'apartment.lease_length': 'required',
         'apartment.numberOfBedrooms': 'required',
         'apartment.preferredBathroom': 'required',
         'apartment.move_in_start_date': 'required | date',
         'apartment.move_in_end_date': 'required | date',
    },
    created() {
        this.initValidator();
        this.noOfBathroomsOptions = this.getCommOptions.bathrooms.map((type, index) => ({...type, checked: index===0}));
        this.leaseOptions = this.getCommOptions.lease_terms;
        this.bedroomsOptions = bedroomTypes.map((type, index) => ({...type, checked: index===0}));
    },
}
</script>
<style scoped>
.input-style{
    border-width: 0;
    border: 1px solid #CECECE;
    padding:5px

}
.char_tracker{
    font-size: 12px;
}
</style>

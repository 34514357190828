<template>
    <div>
        <loader :loading="loading" :backdrop="true"/>
        <div class="flex" v-if="allInfoFilled">
            <div class="w-1/2 bg-gray-100 p-4 border">
                <!-- personal information -->
                <p class="font-bold text-sm text-black">personal information</p>
                <div class="flex borders">
                    <div class="w-1/4">
                        <span class="label">name</span>
                        <p class="text">{{ personalInfo.first_name }} {{ personalInfo.last_name }}</p>

                        <span class="label">phone</span>
                        <p class="text">{{ personalInfo.phone }}</p>
                    </div>
                     <div class="w-1/4">
                    </div>
                    <div class="w-1/4">
                        <span class="label">email</span>
                        <p class="text">{{ personalInfo.email }}</p>

                        <span class="label">contact preference</span>
                        <div class="text"> 
                            <p v-for="(value,index) in contact" :key="index">
                            {{ value.value + ((index + 1) == contact.length ? " " : ", ")}}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- apartment information -->
                <p class="font-bold text-sm text-black mt-8">apartment information</p>
                <div class="flex justify-between borders">
                    <div class="w-1/4">
                        <span class="label">bedrooms</span>
                        <p  class="text"> <span v-for="(bedroom,index) in apartment.numberOfBedrooms"  :key="bedroom.key">{{ bedroom.value + (index != apartment.numberOfBedrooms.length -1 ? ", ":  "" )}} </span></p>
                    </div>
                    <div class="w-1/4">
                        <span class="label">max. rent</span>
                        <p class="text">$ {{ apartment.amount }}</p>
                    </div>
                    <div class="w-1/4">
                        <span class="label">lease length</span>
                        <p class="text">{{  getLeaseLengthValue(apartment.lease_length) }}</p>
                        
                    </div>
                    <div class="w-1/4">
                      <span class="label">bathrooms</span>
                        <p class="text">
                             <span v-for="(bathroom,index) in apartment.preferredBathroom"  :key="bathroom.key">{{ bathroom.value + (index != apartment.preferredBathroom.length -1 ? ", ":  "" )}} </span>
                        </p>
                    </div>
                    
                   
                </div>
                <div class="flex justify-between">
                    
                    <div class="w-1/2">
                        <span class="label">layout</span>
                        <p class="text">{{ apartment.preferredBedroom }}</p>
                    </div>
                  
                     <div class="w-1/2">
                        <span class="label">bathrooms</span>
                        <p class="text">{{ apartment.preferredBathroom }}</p>
                    </div>
                   
                  <!--   <div class="w-1/2">
                        <span class="label">lease length</span>
                        <p class="text">{{ apartment.leaseLength }}</p>
                    </div> -->
                </div>
                <!-- move in -->
                <div class="flex justify-between">
                    <div class="w-1/2">
                        <span class="label">move in</span>
                        <p class="text">{{ moveInDateDisplay.from }} - {{ moveInDateDisplay.to }}</p>
                    </div>
                     <div class="w-1/2">
                      <span class="label">amenities</span>
                        <p class="text"> <span >{{apartment.preferredAmenities}}</span></p>
                    </div>

                </div>  
                <!-- move in -->
                <div class="flex justify-between">
                    <div class="w-1/2">
                        <span class="label">move in</span>
                        <p class="text">{{ moveInDateDisplay.from }} - {{ moveInDateDisplay.to }}</p>
                    </div>
                    <div class="w-1/2">
                        <span class="label">household size</span>
                        <p class="text">{{ apartment.house_hold_size }}</p>
                    </div>
                </div>
            </div>
            <div class="w-1/2 p-4">
                <p class="font-bold text-sm text-black" v-if="hasOccupants">additional occupants</p>
                <div class="mb-8" v-if="hasOccupants">
                    <div v-for="(coResident,index) in coResidents" :key="'resident-'+index" class="w-1/2 inline-block">
                        <span class="label">co-resident</span>
                        <p class="text">{{ coResident.personalInfo.first_name}} {{ coResident.personalInfo.last_name}}</p>
                    </div>
                    <div v-for="(occupant,index) in occupants" :key="'occupant-' +index" class="w-1/2 inline-block">
                        <span class="label">occupant</span>
                        <p class="text">{{ occupant.personalInfo.first_name}} {{ occupant.personalInfo.last_name}}</p>
                    </div>
                    <div v-for="(pet,index) in pets" :key="'pet-'+index" class="w-1/2 inline-block">
                        <span class="label">pet</span>
                        <p class="text">{{ pet.petInfo.name}}</p>
                    </div>
                </div>

                <p class="font-bold text-sm text-black">any additional comments for us?</p>
                <textarea v-model="comments" class="border w-full h-24 p-4 mt-4 focus:outline-none"></textarea>
                <span class="char_tracker">{{(150- (comments ? comments.length : 0 ) )}}/150</span>
            </div>
        </div>
        <modal-footer :footer="footer" :primary="submit" :secondary="back" :tertiary="cancel"></modal-footer>
    </div>
</template>

<script>
import moment from "moment";
import {mapGetters, mapActions} from "vuex";
import EventBus from "@/utils/EventBus";
import Loader from '@/components/ui/Loader';
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import { findValueByKey } from "@/utils/GuestCards";
import { validateGuestCardData } from "@/utils/GuestCards";
export default {
    components: { ModalFooter, Loader },
    mixins: [ ModalNavigation ],
    data: () => {
        return {
            personalInfo: null,
            contact: null,
            apartment: null,
            referral: null,
            coResidents: [],
            occupants: [],
            pets: [],
            comments: '',
            loading: false,
            footer: {
                primaryButton: 'submit',
                secondaryButton: 'back',
                tertiaryButton: 'cancel'
            },
            contactId : false
        }
    },
    computed: {
        ...mapGetters( {
            getCommOptions: 'guest_cards/getCommOptions',
        }),
        moveInDateDisplay(){
            return {
                from : moment(this.apartment.move_in_start_date).format('ll'),
                to   : moment(this.apartment.move_in_end_date).format('ll')
            }
        },
        allInfoFilled() {
            return this.personalInfo && this.contact && this.apartment;
        },
        hasOccupants() {
            return this.coResidents.length || this.occupants.length || this.pets.length;
        },
        contactPreferences() {
            let contacts = [];
            if(this.contact.email)
                contacts.push('email');

            if(this.contact.sms)
                contacts.push('sms');

            if(this.contact.phone)
                contacts.push('phone');

            return contacts.join(', ');
        }
    },
    methods: {
        ...mapActions({
            addGuestCardToStore: 'guest_cards/addGuestCard',
            notifySuccess: 'alerts/notifySuccess',
            notifyError: 'alerts/notifyError',
        }),
        submit() {
            this.loading = true 
            let info = {
                contact_id: this.contactId,
                prospect: this.personalInfo,
                contact: this.contact,
                apartment:  this.apartment,
                referral: this.referral,
                coresidents: this.coResidents,
                pets: this.pets,
                comment:this.comments,
                status: 'open',
                is_archived: false,
                archive_reason: null,
                tour_included:0,
                source:'gc'
            }
            validateGuestCardData(info)
            this.$guestCardDataProvider.create('guestCards',{data:{...info}}).then((response) => {
                if(response.success){
                    this.notifySuccess('Guest card was successfully added.');
                    this.close();
                }else{
                    this.notifyError('Error submitting information.');
                }
                this.loading = false
            }).catch((err)=>{
                console.log(err)
                this.notifyError('Error submitting information.');
            })
        },
        back() {
            EventBus.emit('previous-step');
        },
        cancel() {
            this.close();
        },
        getLeaseLengthValue(key) {
            return findValueByKey(this.getCommOptions.lease_terms, key)
        }
    },
    mounted() {
        EventBus.on('guest-card-personal-info-filled', (payload) => {
            this.contactId = payload.contactId;
            this.personalInfo = payload.personalInfo;
            this.contact = payload.contact;
            this.referral = payload.referral;
            this.coResidents = payload.coResidents;
            this.occupants = payload.occupants;
            this.pets = payload.pets;
        });
        EventBus.on('guest-card-apartment-info-filled', (payload) => {
            this.apartment = payload.apartment;
        });
    }
}
</script>

<style scoped>
span.label {
    @apply text-gray-600 text-xs mt-3 block
}
p.text {
    @apply text-black font-thin text-sm
}
.borders{
    width: 100%;
    border-top: 1px solid #E6E6E6;
    margin-top: 10px;
}
.char_tracker{
    font-size: 12px;
}
</style>
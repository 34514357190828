<template>

    <div class="flex justify-between antialiased">
        <div class="w-1/2">
            <p class="font-bold text-lg text-black mb-4">tell us a little about this person</p>
            <div class="form-row">
                <div class="form-col">
                    <span class="form-label">first name</span>
                    <input type="text" class="form-input spacing-1-2" id="first_name" v-model="personalInfo.first_name" autocomplete="off" />
                    <span v-if="hasError('personalInfo.first_name')" class="form-error">{{ showError('personalInfo.first_name') }}</span>
                </div>
                <div class="form-col">
                    <span class="form-label">last name</span>
                    <input type="text" class="form-input spacing-1-2" id="last_name" v-model="personalInfo.last_name" autocomplete="off" />
                    <span v-if="hasError('personalInfo.last_name')" class="form-error">{{ showError('personalInfo.last_name') }}</span>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <span class="form-label ">phone</span>
                    <input type="text" class="form-input spacing-1-2" maxlength="14" @input="formatPhoneInput" id="phone" v-model="personalInfo.phone" autocomplete="off" />
                    <span v-if="hasError('personalInfo.phone')" class="form-error">{{ showError('personalInfo.phone') }}</span>
                </div>
                <div class="form-col">
                    <span class="form-label ">email</span>
                    <input type="text" class="form-input spacing-1-2" id="email" v-model="personalInfo.email" autocomplete="off" />
                    <span v-if="hasError('personalInfo.email')" class="form-error">{{ showError('personalInfo.email') }}</span>
                </div>
            </div>
            <div class="flex">
                <div class="w-1/8 ">
                   <div >
                        <CheckGroupBoxHorizontal
                        name="contactPreferrences"
                        v-model="contact"
                        label="contact preferences"
                        :options='[{"key":"email","value":"email","checked":true},{"key":"sms","value":"sms","checked":false},{"key":"phone","value":"phone","checked":false}]'
                        editMode
                        />          
                    </div>      
                </div>
            </div>
            
            <div class="flex spacing-1">
                <div class="w-1/2 form-col">
                    <span class="span">referral source</span>
                    <dropdown
                        :options="referralOptions"
                        v-model="referral"
                        class="mt-1"
                    ></dropdown>
                </div>
                 <div class="w-1/2 form-col">
                </div>
            </div>
        </div>

        <div class="w-1/2 pl-8">
            <p class="font-bold text-lg text-black mb-4">will anyone else be living in the unit?</p>
            <unit-details
                :co-residents="coResidents"
                :occupants="occupants"
                :pets="pets"
                @remove-associated-visitor="removeCoResidents"
                @remove-occupant="removeOccupant"
                @remove-pet="removePet"
            >
            </unit-details>
            <co-resident @co-resident-saved="addCoResidents"></co-resident>
            <pet class="spacing-1" @pet-saved="addPet"></pet>
        </div>
        <modal-footer :footer="footer" :primary="next" :tertiary="cancel"></modal-footer>
    </div>

</template>

<script>
import moment from "moment";
import EventBus from "@/utils/EventBus";
import Dropdown from "@/components/ui/Dropdown";

import {mapGetters} from "vuex";
import Pet from "@/components/guest_cards/create/Pet";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import UnitDetails from "@/components/guest_cards/create/UnitDetails";
import CoResident from "@/components/guest_cards/create/CoResident";
import CheckGroupBoxHorizontal from "@/components/guest_cards/common/CheckGroupBoxHorizontal";

export default {
    components: {
        Dropdown,
        ModalFooter,
        CoResident,
        Pet,
        UnitDetails,
        CheckGroupBoxHorizontal
    },
    mixins: [ ModalNavigation ],
    data: () => {
        return {
            personalInfo: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
            },
            contact:{"key":"email","value":"email"},
            referral: null,
            referralOptions: [],
            coResidents: [],
            occupants: [],
            pets: [],
            footer: {
                primaryButton: 'next',
                tertiaryButton: 'cancel'
            },
            contactId : false,
        }
    },
    computed: {
        ...mapGetters( {
            getCommOptions: 'guest_cards/getCommOptions',
        })
    },
    watch: {
        occupants: {
            handler: function() {
                this.occupants.forEach(occupant => {
                    occupant.personalInfo.date_of_birth = moment(occupant.personalInfo.date_of_birth).format('YYYY-MM-D');
                })
            },
            deep: true
        }
    },
    methods: {
        formatPhoneInput(){ 
                var x = this.personalInfo.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.personalInfo.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        next() {
            if(this.validator.passes()) {
                EventBus.emit('guest-card-personal-info-filled', {
                    personalInfo: this.personalInfo,
                    contactId: this.contactId,
                    contact: this.contact,
                    referral: this.referral ? this.referral : this.referralOptions[0].key,
                    coResidents: this.coResidents,
                    occupants: this.occupants,
                    pets: this.pets,
                });
                EventBus.emit('next-step');
            }
        },
        cancel() {
            this.close();
        },
        addCoResidents(payload) {
            this.coResidents.push(payload);
        },
        addOccupant(payload) {
            this.occupants.push(payload);
        },
        addPet(payload) {
            this.pets.push(payload);
        },
        removeCoResidents(payload) {
            let index = -1;
            this.coResidents.forEach( (item,key) => {
                if(item.personalInfo.first_name === payload.personalInfo.first_name && item.personalInfo.last_name === payload.personalInfo.last_name) {
                    index = key;
                }
            });
            this.coResidents = this.coResidents
                .slice(0, index)
                .concat( this.coResidents.slice(index + 1, this.coResidents.length) );
        },
        removeOccupant(payload) {
            let index = -1;
            this.occupants.forEach( (item,key) => {
                if(item.personalInfo.first_name === payload.personalInfo.first_name && item.personalInfo.last_name === payload.personalInfo.last_name) {
                    index = key;
                }
            });
            this.occupants = this.occupants
                .slice(0, index)
                .concat( this.occupants.slice(index + 1, this.occupants.length) );
        },
        removePet(payload) {
            let index = -1;
            this.pets.forEach( (item,key) => {
                if(item.petInfo.name === payload.petInfo.name && item.petInfo.breed === payload.petInfo.breed) {
                    index = key;
                }
            });
            this.pets = this.pets
                .slice(0, index)
                .concat( this.pets.slice(index + 1, this.pets.length) );
        }
    },
    validations: {
        'personalInfo.first_name': 'required',
        'personalInfo.last_name': 'required',
        'personalInfo.email': 'required | email',

    },
    created() {
        this.referralOptions = this.getCommOptions.lead_source;
        this.referral = this.referralOptions[0].key;
        this.initValidator();
    }
}
</script>
<style scope>
.span{
    font-weight: 800;
    font-size: 16px !important;
}
.spacing-1{
    margin-top: 10px !important;
}
.spacing-1-2{
    margin-top: 5px !important;
}
</style>

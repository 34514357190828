<template>
    <Form
       ref="form"
       :submit="() =>  {}"
       :initialValues="null"
       v-if="!loading"
     >
        <div>
            <steps :with-buttons="false">
                <step title="1. personal info">
                    <personal-info />
                </step>
                <step title="2. apartment info">
                    <apartment-info />
                </step>
                <step title="3. submit">
                    <submit-info />
                </step>
            </steps>
        </div>
    </Form>
</template>
<script>
import Form from "@/components/form/Form";
import Auth from "@/mixins/Auth";
import { mapActions } from "vuex";
import Step from "@/components/ui/steps/Step";
import Steps from "@/components/ui/steps/Steps";
import ModalNavigation from "@/mixins/ModalNavigation";
import PersonalInfo from "@/components/guest_cards/create/PersonalInfo";
import ApartmentInfo from "@/components/guest_cards/create/ApartmentInfo";
import SubmitInfo from "@/components/guest_cards/create/SubmitInfo";

export default {
    mixins: [ Auth, ModalNavigation ],
    components: { Steps, Step,Form, PersonalInfo, ApartmentInfo, SubmitInfo },
    data: () => ({
        loading: true,
    }),
    methods: {
        ...mapActions({
            setCommOptions: 'guest_cards/setCommOptions',
        }),
        fetchCommFormOptions() {
            this.$guestCardDataProvider
                .get('communityFormOptions')
                .then((response) => {
                    this.setCommOptions(response);
                    this.loading = false;
                })
                .catch();
        },
    },
    created() {
        this.loading = true;
        this.fetchCommFormOptions();
    }
}
</script>